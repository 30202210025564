// Eigenes CSS
import '../css/style.scss';

// Einfach mal hallo sagen
//console.log('Hallo Welt');

import 'jquery/dist/jquery';
//import 'superfish/src/js/superfish';
//import 'mmenu-js/src/mmenu';
//import 'slideout/dist/slideout';
//import 'zeynepjs';
import 'jquery-match-height/dist/jquery.matchHeight-min';
//import Swiper, { Autoplay, Lazy, EffectFade, EffectCreative } from 'swiper';
//import 'jquery-ui/ui/position';
//import 'bootstrap/js/dist/tooltip';
//import 'bootstrap/js/dist/collapse';
//import { gsap } from "gsap";
//import barba from '@barba/core';



(function( $ ){
    $.fn.myfunction = function() {
       console.log('hello world');
       return this;
    }; 
 })( jQuery );


/****************  INIT  *****************************/

var App = function ( ) {

    function initPageload() {
        //console.log("Pageload");
        //jQuery('html').css("opacity", "0.8");
        jQuery('html').fadeTo(300, 1);
    }




    function initRefreshAfterResize() {
        var dwidth = jQuery(window).width();
        jQuery(window).bind('resize', function(e){
            var wwidth = jQuery(window).width();
            if(dwidth!==wwidth){
            dwidth = jQuery(window).width();
            if (window.RT) clearTimeout(window.RT);
            window.RT = setTimeout(function(){
                this.location.reload(false); /* false to get page from cache */
            }, 1000);
        }
        });
    }

    function initMatchHeight() {
        //console.log("match Height");
        //jQuery(' .news-teaser-3col > div ').matchHeight({byRow: true});
        //jQuery(' .post ').matchHeight({byRow: true});

    }





    function initCF7Styling() {
        /*
        var spans = document.getElementsByClassName("wpcf7-form-control-wrap");
        for(var i=0;i<spans.length;i++)
        {
            var input = spans[i].nextSibling;
            if(input != null)
            {
                if(input.tagName == "LABEL")
                {
                    spans[i].appendChild(input);
                }   
            }   
        }
        */
        let dstext = jQuery(".wpcf7-checkbox.dsgvo .wpcf7-list-item-label").text();
        dstext = dstext.replace("Datenschutzhinweise", "<a href='/datenschutzerklaerung/'>Datenschutzhinweise</a>");
        //console.log(dstext);
        jQuery(".wpcf7-checkbox.dsgvo .wpcf7-list-item-label").html(dstext);
    }




    function initZeynep() {
        var zeynep = jQuery('.zeynep').zeynep({
            load: function (element, options) {
                //console.log('zeynepjs menu is successfully loaded')
              },
            onClosed: function () {
              // enable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "");
        
              
            },
            onOpened: function () {
              // disable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "pointer-events: none;");
        
              
            }
            
        });

        jQuery(".mburger").click(function () {
            if (jQuery("html").hasClass("zeynep-opened")) {
              zeynep.close();
            } else {
              zeynep.open();
            }
        });

        jQuery(".zeynep-overlay").click(function () {
            zeynep.close();
        });
        jQuery("#mobileMenuClose-inner").click(function () {
            zeynep.close();
        });
    }

    function liegenschaftenLayer() {
        /*
        jQuery('.liegenschaften-layer .liegenschaften-layer-item').click(function() {
            jQuery('.liegenschaften-layer .liegenschaften-layer-item').removeClass('action');
            jQuery(this).addClass('action');

        });
        */
        let i=1;
        var interval;
        var intervalouter = setInterval(function() {
            clearInterval(interval);
            //if (!interval) {
            interval = setInterval(function() {
                
                var lcount = jQuery( '.liegenschaften-layer' ).data('count');
                //console.log('interval, count: ' + lcount + ' i: ' + i);

                jQuery( '.liegenschaften-layer .liegenschaften-layer-item' ).removeClass('action');
                jQuery( '.liegenschaften-layer .liegenschaften-layer-item-' + i ).addClass('action');
                //clearInterval(interval);

                if ( i <= lcount ) {
                    i++;
                    
                } else {
                    i = 1;
                    jQuery( '.liegenschaften-layer .liegenschaften-layer-item' ).removeClass('action');
                    clearInterval(interval);
                }
                
            }, 600);
            //}
        }, 12000);

        window.onfocus = function() {
            // Window gains focus, so clear and restart the interval
            if (interval){
                clearInterval(interval);
            }
            clearInterval(intervalouter);
            interval = setInterval(function() {
                //clearInterval(interval);
                var interval = setInterval(function() {
                    
                    var lcount = jQuery( '.liegenschaften-layer' ).data('count');
                    //console.log('interval, count: ' + lcount + ' i: ' + i);
    
    
                    jQuery( '.liegenschaften-layer .liegenschaften-layer-item' ).removeClass('action');
                    jQuery( '.liegenschaften-layer .liegenschaften-layer-item-' + i ).addClass('action');
                    //clearInterval(interval);
    
                    if ( i <= lcount ) {
                        i++;
                        
                    } else {
                        i = 1;
                        jQuery( '.liegenschaften-layer .liegenschaften-layer-item' ).removeClass('action');
                        clearInterval(interval);
                    }
                    
                }, 600);
            }, 12000);
    
        };
        
        window.onblur = function() {
            // Window loses focus, clear the interval
            if (interval){
                clearInterval(interval);
            }
            clearInterval(intervalouter);
        };
        
    }

    function scrollcheck() {
        //console.log("scrollcheck");
        jQuery(window).scroll(function () {
            if (jQuery(window).scrollTop() > 25) {
                jQuery('#overlay').animate({opacity: 0}, 500).delay(500).fadeOut(100);
                jQuery('#overlay-plantatree').removeClass('overlay-active');
                //fadeOut(400, "linear");
            }
        });
    }

    function barbaInit() {
        barba.init({
            transitions: [{
                name: 'opacity-transition',
                leave(data) {
                  return gsap.to(data.current.container, {
                    opacity: 0
                  });
                },
                enter(data) {
                  return gsap.from(data.next.container, {
                    opacity: 0
                  });
                }
              }]
        });
    }




    return {
        init: function () {
            initPageload();
            // initRefreshAfterResize();
            // initMatchHeight();
            //initCF7Styling();
            liegenschaftenLayer();
            scrollcheck();
           // barbaInit();
        }
    }
}( );


jQuery(document).ready(function () {
    //console.log('ready  ??');
    App.init();
});



